//  Fonts via Google Fonts
//Space Grotesk defaults to weight 700 and 16px

@mixin spaceG {
  font-family: $spaceG;
  font-size: 16px;
  font-weight: 700;
}

//Source Sans Pro defaults to weight 400 and 16px

@mixin sourcepro {
  font-family: $sourcepro;
  font-weight: 400;
  font-size: 16px;
}

//Source Code Pro defaults to weight 400 16px

@mixin scodepro {
  font-family: $scodepro;
  font-weight: 400;
  font-size: 16px;
}

.spaceG {
  @include spaceG;
}
.sourcepro {
  @include sourcepro;
}
.scodepro {
  @include scodepro;
}
