.filter-bar {
  height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  top: 64px;
  justify-content: left;
  z-index: 10;
  background-color: #191919;

  input[type="search"] {
    @include sourcepro;
    border-radius: 36px;
    background: #232423;
    width: 346px;
    height: 32px;
    padding-left: 16px;
    color: #ffffff;

    &:focus {
      border: 1px solid rgba(80, 243, 106, 0.51);
    }
  }

  &__wrapper {
    margin-right: 16px;
    position: relative;
    padding-left: 24px;
  }

  &__search-icon {
    background: #50f36a !important;
    width: 24px;
    height: 24px;
    border-radius: 50% !important;
    position: absolute !important;
    left: 339px;
    bottom: 4px;
    border: none !important;
    svg {
      height: 12px;
      width: 12px;
    }
  }
}
