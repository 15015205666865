.location_wrapper {
  background-color: #2b2c2b;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 71px;
  border-radius: 50px;

  .filter-bar__wrapper {
    width: 100%;

    form {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;

      input {
        height: 100%;
        background-color: #2b2c2b;
        font-size: 24px;
        width: 100%;
        color: #fff;
        opacity: 0.6;
      }

      button:not(.MuiButton-root) {
        position: inherit !important;
        width: 48px;
        height: 48px;

        svg {
          height: 24px;
          width: 24px;
        }
      }
    }
  }
}
