.report-view {
  background-color: #191919;
  padding: 20px;

  &__container {
    max-width: 722px;
    margin: 0 auto;
    min-width: 722px;
  }
}
