@import url("https://fonts.googleapis.com/css?family=Space+Grotesk");

html,
body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: "Space Grotesk", sans-serif;
  overflow: hidden;
}

*,
*::after,
*::before {
  box-sizing: inherit;
  font-family: inherit;
  line-height: inherit;
  color: inherit;
}

*:focus {
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li {
  margin: 0;
  padding: 0;
}

input {
  // disable Safari input style override
  -webkit-appearance: none;
}

input,
textarea {
  outline: 0;
  padding: 0;
  border: none;
}

.scroll {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.app {
  display: flex;
}

pre {
  margin: 6px;
  //padding: 10px 20px;
}

.MuiDialog-paper, .MuiPopover-paper {
  width: 836px;
  max-width: 836px !important;
  background-color: #191919 !important;
  border: 1px solid #030303;
  height: 100%;
}

.MuiMenu-paper {
  width: inherit;
  max-width: inherit !important;
  height: inherit;
}

.MuiAlert-Message {
  font-size: 16px;
}

.MuiDialog-paper {
  padding: 56px;
}

.report {
  overflow: scroll;
  margin-top: 64px;
}

.flex {
  display: flex;

  &--align-center {
    align-items: center;
  }
  &--align-end {
    align-items: flex-end;
  }

  &--justify-center {
    justify-content: center;
  }

  &--justify-sb {
    justify-content: space-between;
  }

  &--column {
    flex-direction: column;
  }
}

.mapboxgl-popup-close-button,
.mapboxgl-popup-tip {
  display: none;
}

.mapboxgl-popup-content {
  font-family: Source Sans Pro, serif;
  font-weight: 400;
  font-size: 12px;
  color: white;
  background-color: black;
  margin: 6px;
  display: flex;
  flex-direction: column;
}

.MuiPagination-ul {
  & > li {
    &:not(:first-child) {
      display: none;
    }

    &:last-child {
      display: inherit;
    }
  }
}

.MuiPaginationItem-page {
  width: 32px !important;
  height: 24px !important;
  background-color: #232423 !important;
}

.MuiFormControlLabel-root {
  color: white;
}

.MuiTableCell-body {
  color: unset !important;
}

.MuiButton-label {
  font-family: "Space Grotesk", sans-serif;
}

.loader-wrapper {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #191919;
  color: white;
  font-size: 24px;
}

svg.grey {
  circle {
    fill: #fff;
    opacity: 0.6;
  }
}

svg.white {
  circle {
    fill: #fff;
  }
}

.MuiTooltip-tooltip {
  font-size: 12px !important;;
}

.map-loader {
  height: calc(100% - 120px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(25, 25, 25, 0.7);
  color: white;
  font-size: 24px;
  position: fixed;
  bottom: 0;
  z-index: 9999;
  left: 0;
  margin-left: 678px;
  width: calc(100% - 678px);
}
