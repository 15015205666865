.app-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #191919;
  box-shadow: inset 0px -1px 0px #121212;
  height: 72px;
  position: relative;

  &__center {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: center;
    color: #fff;
    font-size: 12px;
    letter-spacing: 0.2px;
    position: absolute;
  }

  &__right {
    display: flex;
    width: fit-content;
    margin: auto 24px 0 auto;
    z-index: 999;

    a, button {
      color: #50F36A;
      text-decoration: none;
      cursor: pointer;
      background-color: transparent;

      &:hover{
        color: #AEF9BA;
      }
    }
  }
}
