.active-huc {
  padding: 26px 0;
  height: 68px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 24px;
  margin-right: 24px;
  border-bottom: 1px dashed grey;
}
