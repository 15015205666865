.grid {
  display: grid;
  grid-gap: 24px;
  // default grid children will be 2-up
  grid-template-columns: repeat(2, 1fr);

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  &.no-col-gap {
    grid-gap: 0 24px;
  }

  &.small-gap {
    grid-gap: 16px;
  }

  &.no-gap {
    grid-gap: 0;
  }

  &.full {
    height: 100%;
  }

  + .grid {
    margin-top: 24px;
  }

  &__full-row {
    grid-column: 1 / -1;
  }

  &[data-columns='2'] {grid-template-columns: repeat(2, 1fr)}
  &[data-columns='3'] {grid-template-columns: repeat(3, 1fr)}
  &[data-columns='4'] {grid-template-columns: repeat(4, 1fr)}
  &[data-columns='5'] {grid-template-columns: repeat(5, 1fr)}

  &[data-columns='100px-1'] {grid-template-columns: 150px 1fr}
  &[data-columns='100px-2'] {grid-template-columns: 150px repeat(2, 1fr)}
  &[data-columns='100px-3'] {grid-template-columns: 150px repeat(3, 1fr)}
  &[data-columns='100px-4'] {grid-template-columns: 150px repeat(4, 1fr)}

  &[data-gap='6px'] {grid-gap: 6px;}

  &__chart {
    grid-column: 1 / 4;
    grid-row: 1 / 5;
  }

  &--1 {
    grid-template-columns: 1fr;
  }
  &--1x1x1 {
    grid-template-columns: repeat(3, minmax(186px, 1fr));
  }

  &--2x1x1 {
    grid-template-columns: calc(50% - 13px) 1fr 1fr;
  }

  &--1x2x2 {
    grid-template-columns: calc(7% - 13px) 1fr 1fr;
  }

  &--1x1x2 {
    grid-template-columns: 1fr 1fr calc(50% - 13px);
  }

  &--1x1x1x1 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
