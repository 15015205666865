.button {
  @include sourcepro;
  background-color: #232423;
  border-radius: 44px;

  & span {
    color: #ffffff;
  }

  &--sm {
    font-size: 14px;
    padding: 4px 12px;
  }

  &--lg {
    font-weight: 600;
    padding: 8px 16px;
  }

  &:hover {
    background-color: #323432;
  }

  &:focus {
    background-color: #282a28;
  }

  &:disabled {
    background-color: #232423;
    opacity: 80%;
  }
}
