.side-panel {
  flex-shrink: 0;
  overflow-y: scroll;
  position: fixed;
  z-index: 9;
  background: $black;
  box-shadow: inset -1px 0px 0px $shadow;
  height: calc(100vh - 115px);
  margin-top: 115px;
  width: 678px;
}
